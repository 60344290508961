{
    "version": "0.0.0",
    "name": "candy_shop",
    "instructions": [
        {
            "name": "createCandyShop",
            "accounts": [
                {
                    "name": "treasuryMint",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "payer",
                    "isMut": true,
                    "isSigner": true
                },
                {
                    "name": "authority",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "feeWithdrawalDestination",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "treasuryWithdrawalDestination",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "treasuryWithdrawalDestinationOwner",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "auctionHouse",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "auctionHouseFeeAccount",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "auctionHouseTreasury",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "candyShop",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "ahProgram",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "tokenProgram",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "associatedTokenProgram",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "systemProgram",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "rent",
                    "isMut": false,
                    "isSigner": false
                }
            ],
            "args": [
                {
                    "name": "sellerFeeBasisPoints",
                    "type": "u16"
                },
                {
                    "name": "requiresSignOff",
                    "type": "bool"
                },
                {
                    "name": "canChangeSalePrice",
                    "type": "bool"
                },
                {
                    "name": "bump",
                    "type": "u8"
                },
                {
                    "name": "feePayerBump",
                    "type": "u8"
                },
                {
                    "name": "treasuryBump",
                    "type": "u8"
                },
                {
                    "name": "split",
                    "type": "u8"
                },
                {
                    "name": "name",
                    "type": "string"
                }
            ]
        },
        {
            "name": "createEnterpriseCandyShop",
            "accounts": [
                {
                    "name": "treasuryMint",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "payer",
                    "isMut": true,
                    "isSigner": true
                },
                {
                    "name": "authority",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "feeWithdrawalDestination",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "treasuryWithdrawalDestination",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "treasuryWithdrawalDestinationOwner",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "auctionHouse",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "auctionHouseFeeAccount",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "auctionHouseTreasury",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "candyShop",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "ahProgram",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "tokenProgram",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "associatedTokenProgram",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "systemProgram",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "rent",
                    "isMut": false,
                    "isSigner": false
                }
            ],
            "args": [
                {
                    "name": "sellerFeeBasisPoints",
                    "type": "u16"
                },
                {
                    "name": "requiresSignOff",
                    "type": "bool"
                },
                {
                    "name": "canChangeSalePrice",
                    "type": "bool"
                },
                {
                    "name": "bump",
                    "type": "u8"
                },
                {
                    "name": "feePayerBump",
                    "type": "u8"
                },
                {
                    "name": "treasuryBump",
                    "type": "u8"
                },
                {
                    "name": "splits",
                    "type": {
                        "array": ["u8", 4]
                    }
                },
                {
                    "name": "name",
                    "type": "string"
                }
            ]
        },
        {
            "name": "updateCandyShopInfo",
            "accounts": [
                {
                    "name": "treasuryMint",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "wallet",
                    "isMut": true,
                    "isSigner": true
                },
                {
                    "name": "candyShopCreator",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "authority",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "feeWithdrawalDestination",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "treasuryWithdrawalDestination",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "treasuryWithdrawalDestinationOwner",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "auctionHouse",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "candyShop",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "ahProgram",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "tokenProgram",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "associatedTokenProgram",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "systemProgram",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "rent",
                    "isMut": false,
                    "isSigner": false
                }
            ],
            "args": [
                {
                    "name": "sellerFeeBasisPoints",
                    "type": {
                        "option": "u16"
                    }
                },
                {
                    "name": "requiresSignOff",
                    "type": {
                        "option": "bool"
                    }
                },
                {
                    "name": "canChangeSalePrice",
                    "type": {
                        "option": "bool"
                    }
                },
                {
                    "name": "split",
                    "type": {
                        "option": "u8"
                    }
                },
                {
                    "name": "authorityBump",
                    "type": "u8"
                }
            ]
        },
        {
            "name": "sellWithProxy",
            "accounts": [
                {
                    "name": "wallet",
                    "isMut": true,
                    "isSigner": true
                },
                {
                    "name": "tokenAccount",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "metadata",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "authority",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "auctionHouse",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "auctionHouseFeeAccount",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "sellerTradeState",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "freeSellerTradeState",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "candyShop",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "ahProgram",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "tokenProgram",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "systemProgram",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "programAsSigner",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "rent",
                    "isMut": false,
                    "isSigner": false
                }
            ],
            "args": [
                {
                    "name": "price",
                    "type": "u64"
                },
                {
                    "name": "amount",
                    "type": "u64"
                },
                {
                    "name": "tradeStateBump",
                    "type": "u8"
                },
                {
                    "name": "freeTradeStateBump",
                    "type": "u8"
                },
                {
                    "name": "programAsSignerBump",
                    "type": "u8"
                }
            ]
        },
        {
            "name": "buyWithProxy",
            "accounts": [
                {
                    "name": "wallet",
                    "isMut": false,
                    "isSigner": true
                },
                {
                    "name": "paymentAccount",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "transferAuthority",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "treasuryMint",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "tokenAccount",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "metadata",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "escrowPaymentAccount",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "authority",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "auctionHouse",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "auctionHouseFeeAccount",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "buyerTradeState",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "candyShop",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "ahProgram",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "tokenProgram",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "systemProgram",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "rent",
                    "isMut": false,
                    "isSigner": false
                }
            ],
            "args": [
                {
                    "name": "price",
                    "type": "u64"
                },
                {
                    "name": "amount",
                    "type": "u64"
                },
                {
                    "name": "tradeStateBump",
                    "type": "u8"
                },
                {
                    "name": "escrowPaymentBump",
                    "type": "u8"
                }
            ]
        },
        {
            "name": "executeSaleWithProxy",
            "accounts": [
                {
                    "name": "buyer",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "seller",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "tokenAccount",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "tokenMint",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "metadata",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "treasuryMint",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "escrowPaymentAccount",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "sellerPaymentReceiptAccount",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "buyerReceiptTokenAccount",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "authority",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "auctionHouse",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "auctionHouseFeeAccount",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "auctionHouseTreasury",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "buyerTradeState",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "sellerTradeState",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "freeTradeState",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "candyShop",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "ahProgram",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "tokenProgram",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "systemProgram",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "associatedTokenProgram",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "programAsSigner",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "rent",
                    "isMut": false,
                    "isSigner": false
                }
            ],
            "args": [
                {
                    "name": "price",
                    "type": "u64"
                },
                {
                    "name": "amount",
                    "type": "u64"
                },
                {
                    "name": "escrowPaymentBump",
                    "type": "u8"
                },
                {
                    "name": "freeTradeStateBump",
                    "type": "u8"
                },
                {
                    "name": "programAsSignerBump",
                    "type": "u8"
                },
                {
                    "name": "buySide",
                    "type": "bool"
                }
            ]
        },
        {
            "name": "executeEnterpriseSaleWithProxy",
            "accounts": [
                {
                    "name": "buyer",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "seller",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "tokenAccount",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "tokenMint",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "metadata",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "treasuryMint",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "escrowPaymentAccount",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "sellerPaymentReceiptAccount",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "buyerReceiptTokenAccount",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "authority",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "auctionHouse",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "auctionHouseFeeAccount",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "auctionHouseTreasury",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "buyerTradeState",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "sellerTradeState",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "freeTradeState",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "candyShop",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "treasuryWithdrawalDestination",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "ahProgram",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "tokenProgram",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "systemProgram",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "associatedTokenProgram",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "programAsSigner",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "rent",
                    "isMut": false,
                    "isSigner": false
                }
            ],
            "args": [
                {
                    "name": "price",
                    "type": "u64"
                },
                {
                    "name": "amount",
                    "type": "u64"
                },
                {
                    "name": "escrowPaymentBump",
                    "type": "u8"
                },
                {
                    "name": "freeTradeStateBump",
                    "type": "u8"
                },
                {
                    "name": "programAsSignerBump",
                    "type": "u8"
                },
                {
                    "name": "buySide",
                    "type": "bool"
                }
            ]
        },
        {
            "name": "candyShopWithdrawFromTreasury",
            "accounts": [
                {
                    "name": "candyShop",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "candyShopCreator",
                    "isMut": false,
                    "isSigner": true
                },
                {
                    "name": "candyShopCreatorTokenAccount",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "liqnftTreasuryAccount",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "liqnftTreasuryTokenAccount",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "treasuryMint",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "authority",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "treasuryWithdrawalDestination",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "auctionHouseTreasury",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "auctionHouse",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "ahProgram",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "tokenProgram",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "systemProgram",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "associatedTokenProgram",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "rent",
                    "isMut": false,
                    "isSigner": false
                }
            ],
            "args": [
                {
                    "name": "amount",
                    "type": "u64"
                }
            ]
        },
        {
            "name": "cancelWithProxy",
            "accounts": [
                {
                    "name": "wallet",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "tokenAccount",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "tokenMint",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "authority",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "auctionHouse",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "auctionHouseFeeAccount",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "tradeState",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "candyShop",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "ahProgram",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "tokenProgram",
                    "isMut": false,
                    "isSigner": false
                }
            ],
            "args": [
                {
                    "name": "price",
                    "type": "u64"
                },
                {
                    "name": "amount",
                    "type": "u64"
                }
            ]
        },
        {
            "name": "createAuction",
            "accounts": [
                {
                    "name": "auction",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "auctionEscrow",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "wallet",
                    "isMut": true,
                    "isSigner": true
                },
                {
                    "name": "tokenAccount",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "nftMint",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "authority",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "candyShop",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "tokenProgram",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "associatedTokenProgram",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "systemProgram",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "rent",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "clock",
                    "isMut": false,
                    "isSigner": false
                }
            ],
            "args": [
                {
                    "name": "startingBid",
                    "type": "u64"
                },
                {
                    "name": "startTime",
                    "type": "u64"
                },
                {
                    "name": "biddingPeriod",
                    "type": "u64"
                },
                {
                    "name": "tickSize",
                    "type": "u64"
                },
                {
                    "name": "buyNowPrice",
                    "type": {
                        "option": "u64"
                    }
                },
                {
                    "name": "extensionPeriod",
                    "type": {
                        "option": "u64"
                    }
                },
                {
                    "name": "extensionIncrement",
                    "type": {
                        "option": "u64"
                    }
                }
            ]
        },
        {
            "name": "cancelAuction",
            "accounts": [
                {
                    "name": "auction",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "auctionEscrow",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "wallet",
                    "isMut": true,
                    "isSigner": true
                },
                {
                    "name": "tokenAccount",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "nftMint",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "authority",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "candyShop",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "tokenProgram",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "associatedTokenProgram",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "systemProgram",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "rent",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "clock",
                    "isMut": false,
                    "isSigner": false
                }
            ],
            "args": [
                {
                    "name": "auctionBump",
                    "type": "u8"
                }
            ]
        },
        {
            "name": "makeBid",
            "accounts": [
                {
                    "name": "wallet",
                    "isMut": true,
                    "isSigner": true
                },
                {
                    "name": "auction",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "candyShop",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "bid",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "auctionBidWallet",
                    "isMut": true,
                    "isSigner": false,
                    "docs": ["System account for perfoming transfers on behalf of an auction bid account"]
                },
                {
                    "name": "nftMint",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "bidPaymentAccount",
                    "isMut": true,
                    "isSigner": false,
                    "docs": ["Account that will be used to pay into `escrow_payment_account` on behalf of user"]
                },
                {
                    "name": "userTreasuryAta",
                    "isMut": true,
                    "isSigner": false,
                    "docs": ["User account for paying bid when auction treasury is SPL token"]
                },
                {
                    "name": "treasuryMint",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "auctionEscrow",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "metadata",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "escrowPaymentAccount",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "authority",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "auctionHouse",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "auctionHouseFeeAccount",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "bidTradeState",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "ahProgram",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "tokenProgram",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "associatedTokenProgram",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "systemProgram",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "clock",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "rent",
                    "isMut": false,
                    "isSigner": false
                }
            ],
            "args": [
                {
                    "name": "bidPrice",
                    "type": "u64"
                },
                {
                    "name": "auctionBidWalletBump",
                    "type": "u8"
                },
                {
                    "name": "tradeStateBump",
                    "type": "u8"
                },
                {
                    "name": "escrowPaymentBump",
                    "type": "u8"
                }
            ]
        },
        {
            "name": "withdrawBid",
            "accounts": [
                {
                    "name": "wallet",
                    "isMut": true,
                    "isSigner": true
                },
                {
                    "name": "auction",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "candyShop",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "bid",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "auctionBidWallet",
                    "isMut": true,
                    "isSigner": false,
                    "docs": ["System account for perfoming transfers on behalf of an auction bid account"]
                },
                {
                    "name": "userTreasuryAta",
                    "isMut": true,
                    "isSigner": false,
                    "docs": ["User account for receiving withdrawal when auction treasury is SPL token"]
                },
                {
                    "name": "bidReceiptAccount",
                    "isMut": true,
                    "isSigner": false,
                    "docs": ["Account that will receive withdrawal from `escrow_payment_account` on behalf of user"]
                },
                {
                    "name": "nftMint",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "treasuryMint",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "auctionEscrow",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "metadata",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "escrowPaymentAccount",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "authority",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "auctionHouse",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "auctionHouseFeeAccount",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "bidTradeState",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "ahProgram",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "tokenProgram",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "associatedTokenProgram",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "systemProgram",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "clock",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "rent",
                    "isMut": false,
                    "isSigner": false
                }
            ],
            "args": [
                {
                    "name": "auctionBidWalletBump",
                    "type": "u8"
                },
                {
                    "name": "escrowReceiptBump",
                    "type": "u8"
                }
            ]
        },
        {
            "name": "buyNow",
            "accounts": [
                {
                    "name": "wallet",
                    "isMut": true,
                    "isSigner": true
                },
                {
                    "name": "seller",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "sellerPaymentReceiptAccount",
                    "isMut": true,
                    "isSigner": false,
                    "docs": ["Seller SOL or SPL account to receive payment at."]
                },
                {
                    "name": "auction",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "candyShop",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "paymentAccount",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "transferAuthority",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "nftMint",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "treasuryMint",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "auctionEscrow",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "metadata",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "escrowPaymentAccount",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "auctionPaymentReceiptAccount",
                    "isMut": true,
                    "isSigner": false,
                    "docs": ["Account that will receive SPL or SOL payment on behalf of seller"]
                },
                {
                    "name": "buyerReceiptTokenAccount",
                    "isMut": true,
                    "isSigner": false,
                    "docs": ["Buyer SPL token account to receive purchased item at."]
                },
                {
                    "name": "authority",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "auctionHouse",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "auctionHouseFeeAccount",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "auctionHouseTreasury",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "buyerTradeState",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "auctionTradeState",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "freeAuctionTradeState",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "ahProgram",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "tokenProgram",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "associatedTokenProgram",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "systemProgram",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "programAsSigner",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "clock",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "rent",
                    "isMut": false,
                    "isSigner": false
                }
            ],
            "args": [
                {
                    "name": "auctionBump",
                    "type": "u8"
                },
                {
                    "name": "auctionTradeStateBump",
                    "type": "u8"
                },
                {
                    "name": "buyerTradeStateBump",
                    "type": "u8"
                },
                {
                    "name": "escrowPaymentBump",
                    "type": "u8"
                },
                {
                    "name": "freeAuctionTradeStateBump",
                    "type": "u8"
                },
                {
                    "name": "programAsSignerBump",
                    "type": "u8"
                }
            ]
        },
        {
            "name": "settleAuction",
            "accounts": [
                {
                    "name": "wallet",
                    "isMut": true,
                    "isSigner": true
                },
                {
                    "name": "buyer",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "auction",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "candyShop",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "bid",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "auctionBidWallet",
                    "isMut": true,
                    "isSigner": false,
                    "docs": ["System account for perfoming transfers on behalf of an auction bid account"]
                },
                {
                    "name": "bidReceiptTokenAccount",
                    "isMut": true,
                    "isSigner": false,
                    "docs": ["Account that will receive purchased SPL token on behalf of buyer."]
                },
                {
                    "name": "nftMint",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "treasuryMint",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "auctionEscrow",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "metadata",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "escrowPaymentAccount",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "auctionPaymentReceiptAccount",
                    "isMut": true,
                    "isSigner": false,
                    "docs": ["Account that will receive SPL or SOL payment on behalf of seller"]
                },
                {
                    "name": "authority",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "auctionHouse",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "auctionHouseFeeAccount",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "auctionHouseTreasury",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "bidTradeState",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "auctionTradeState",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "freeAuctionTradeState",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "ahProgram",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "tokenProgram",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "associatedTokenProgram",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "systemProgram",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "programAsSigner",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "clock",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "rent",
                    "isMut": false,
                    "isSigner": false
                }
            ],
            "args": [
                {
                    "name": "auctionBump",
                    "type": "u8"
                },
                {
                    "name": "auctionBidWalletBump",
                    "type": "u8"
                },
                {
                    "name": "auctionTradeStateBump",
                    "type": "u8"
                },
                {
                    "name": "freeAuctionTradeStateBump",
                    "type": "u8"
                },
                {
                    "name": "escrowPaymentBump",
                    "type": "u8"
                },
                {
                    "name": "programAsSignerBump",
                    "type": "u8"
                }
            ]
        },
        {
            "name": "distributeAuctionProceeds",
            "accounts": [
                {
                    "name": "wallet",
                    "isMut": true,
                    "isSigner": true
                },
                {
                    "name": "seller",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "buyer",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "buyerReceiptTokenAccount",
                    "isMut": true,
                    "isSigner": false,
                    "docs": ["Buyer SPL token account to receive purchased item at."]
                },
                {
                    "name": "sellerPaymentReceiptAccount",
                    "isMut": true,
                    "isSigner": false,
                    "docs": ["Seller SOL or SPL account to receive payment at."]
                },
                {
                    "name": "auction",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "candyShop",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "bid",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "auctionBidWallet",
                    "isMut": true,
                    "isSigner": false,
                    "docs": ["System account for perfoming transfers on behalf of an auction bid account"]
                },
                {
                    "name": "bidReceiptTokenAccount",
                    "isMut": true,
                    "isSigner": false,
                    "docs": ["Account that will receive purchased SPL token on behalf of buyer."]
                },
                {
                    "name": "nftMint",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "treasuryMint",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "auctionPaymentReceiptAccount",
                    "isMut": true,
                    "isSigner": false,
                    "docs": ["Account that will receive SPL or SOL payment on behalf of seller"]
                },
                {
                    "name": "tokenProgram",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "associatedTokenProgram",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "systemProgram",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "clock",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "rent",
                    "isMut": false,
                    "isSigner": false
                }
            ],
            "args": [
                {
                    "name": "auctionBump",
                    "type": "u8"
                },
                {
                    "name": "auctionBidWalletBump",
                    "type": "u8"
                }
            ]
        }
    ],
    "accounts": [
        {
            "name": "CandyShopV1",
            "type": {
                "kind": "struct",
                "fields": [
                    {
                        "name": "key",
                        "type": {
                            "defined": "AccountType"
                        }
                    },
                    {
                        "name": "name",
                        "type": {
                            "array": ["u8", 32]
                        }
                    },
                    {
                        "name": "creator",
                        "type": "publicKey"
                    },
                    {
                        "name": "treasuryMint",
                        "type": "publicKey"
                    },
                    {
                        "name": "split",
                        "type": "u8"
                    }
                ]
            }
        },
        {
            "name": "EnterpriseCandyShopV1",
            "type": {
                "kind": "struct",
                "fields": [
                    {
                        "name": "key",
                        "type": {
                            "defined": "AccountType"
                        }
                    },
                    {
                        "name": "name",
                        "type": {
                            "array": ["u8", 32]
                        }
                    },
                    {
                        "name": "creator",
                        "type": "publicKey"
                    },
                    {
                        "name": "treasuryMint",
                        "type": "publicKey"
                    },
                    {
                        "name": "coOwners",
                        "type": {
                            "array": ["publicKey", 4]
                        }
                    },
                    {
                        "name": "splits",
                        "type": {
                            "array": ["u8", 4]
                        }
                    }
                ]
            }
        },
        {
            "name": "AuctionV1",
            "type": {
                "kind": "struct",
                "fields": [
                    {
                        "name": "key",
                        "type": {
                            "defined": "AccountType"
                        }
                    },
                    {
                        "name": "candyShop",
                        "type": "publicKey"
                    },
                    {
                        "name": "nftMint",
                        "type": "publicKey"
                    },
                    {
                        "name": "seller",
                        "type": "publicKey"
                    },
                    {
                        "name": "startingBid",
                        "type": "u64"
                    },
                    {
                        "name": "startTime",
                        "type": "u64"
                    },
                    {
                        "name": "biddingPeriod",
                        "type": "u64"
                    },
                    {
                        "name": "tickSize",
                        "type": "u64"
                    },
                    {
                        "name": "highestBid",
                        "type": {
                            "option": {
                                "defined": "BidTuple"
                            }
                        }
                    },
                    {
                        "name": "buyNowPrice",
                        "type": {
                            "option": "u64"
                        }
                    },
                    {
                        "name": "status",
                        "type": {
                            "defined": "AuctionStatus"
                        }
                    },
                    {
                        "name": "extensionPeriod",
                        "type": {
                            "option": "u64"
                        }
                    },
                    {
                        "name": "extensionIncrement",
                        "type": {
                            "option": "u64"
                        }
                    }
                ]
            }
        },
        {
            "name": "Bid",
            "type": {
                "kind": "struct",
                "fields": [
                    {
                        "name": "key",
                        "type": {
                            "defined": "AccountType"
                        }
                    },
                    {
                        "name": "price",
                        "type": "u64"
                    },
                    {
                        "name": "buyer",
                        "type": "publicKey"
                    }
                ]
            }
        }
    ],
    "types": [
        {
            "name": "BidTuple",
            "type": {
                "kind": "struct",
                "fields": [
                    {
                        "name": "key",
                        "type": "publicKey"
                    },
                    {
                        "name": "price",
                        "type": "u64"
                    }
                ]
            }
        },
        {
            "name": "AccountType",
            "type": {
                "kind": "enum",
                "variants": [
                    {
                        "name": "CandyShopV1"
                    },
                    {
                        "name": "AuctionV1"
                    },
                    {
                        "name": "Bid"
                    },
                    {
                        "name": "EnterpriseCandyShopV1"
                    }
                ]
            }
        },
        {
            "name": "AuctionStatus",
            "type": {
                "kind": "enum",
                "variants": [
                    {
                        "name": "Created"
                    },
                    {
                        "name": "Started"
                    },
                    {
                        "name": "Complete"
                    }
                ]
            }
        }
    ],
    "errors": [
        {
            "code": 6000,
            "name": "InvalidFeeSplit",
            "msg": "Invalid fee split"
        },
        {
            "code": 6001,
            "name": "InvalidSfbp",
            "msg": "Invalid seller fee basis points"
        },
        {
            "code": 6002,
            "name": "NameTooLong",
            "msg": "Name too long"
        },
        {
            "code": 6003,
            "name": "NumericalOverflow",
            "msg": "Numerical Overflow"
        },
        {
            "code": 6004,
            "name": "BuyNowPriceTooLow",
            "msg": "Buy now price must be higher than starting bid"
        },
        {
            "code": 6005,
            "name": "InvalidStartTime",
            "msg": "Start time cannot be in the past"
        },
        {
            "code": 6006,
            "name": "InvalidTickSize",
            "msg": "The tick size must be greater than 0"
        },
        {
            "code": 6007,
            "name": "NotCSCreator",
            "msg": "The caller of this instruction must be the Candy Shop creator"
        },
        {
            "code": 6008,
            "name": "AuctionStarted",
            "msg": "Cannot cancel an auction that has already begun"
        },
        {
            "code": 6009,
            "name": "AuctionOver",
            "msg": "The bidding period for this auction is over"
        },
        {
            "code": 6010,
            "name": "AuctionNotStarted",
            "msg": "The bidding period for this auction has not started"
        },
        {
            "code": 6011,
            "name": "AuctionNotOver",
            "msg": "The bidding period for this auction is not over"
        },
        {
            "code": 6012,
            "name": "CannotCancel",
            "msg": "Auction has a winning bid. It cannot be cancelled"
        },
        {
            "code": 6013,
            "name": "InvalidSeller",
            "msg": "The provided seller is not the one who created the auction"
        },
        {
            "code": 6014,
            "name": "InvalidBuyer",
            "msg": "The provided buyer is not the one who made the bid"
        },
        {
            "code": 6015,
            "name": "BidTooLow",
            "msg": "Placed bid does not beat current highest bid price by a sufficient amount"
        },
        {
            "code": 6016,
            "name": "BidTooHigh",
            "msg": "Provided bid price is greater than `buy_now_price`. Please call `BuyNow` instruction instead"
        },
        {
            "code": 6017,
            "name": "LeadingBid",
            "msg": "Cannot remove the leading bid in this auction"
        },
        {
            "code": 6018,
            "name": "BuyNowNotEnabled",
            "msg": "Buy now is not available on this auction"
        },
        {
            "code": 6019,
            "name": "NoValidBids",
            "msg": "No valid bids were placed in this auction"
        },
        {
            "code": 6020,
            "name": "InvalidBidAccountPassed",
            "msg": "Provided bid account does not match the highest bid for the auction"
        },
        {
            "code": 6021,
            "name": "Unauthorized",
            "msg": "Caller is not authorized to perform this action"
        },
        {
            "code": 6022,
            "name": "InvalidCoOwnerAccounts",
            "msg": "Co-owner accounts invalid"
        },
        {
            "code": 6023,
            "name": "TokenAccountUninitialized",
            "msg": "Token Account not initialized"
        },
        {
            "code": 6024,
            "name": "InvalidAccountType",
            "msg": "Invalid account type"
        },
        {
            "code": 6025,
            "name": "MissingExtensionSetting",
            "msg": "Both extension settings must be provided to use this functionality"
        },
        {
            "code": 6026,
            "name": "InvalidExtensionSettings",
            "msg": "Extension period must not be greater than the extension increment"
        },
        {
            "code": 6027,
            "name": "InvalidBiddingPeriod",
            "msg": "Invalid bidding period"
        }
    ],
    "metadata": {
        "address": "csbMUULiQfGjT8ezT16EoEBaiarS6VWRevTw1JMydrS"
    }
}
