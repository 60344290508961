import { WalletMultiButton } from '@solana/wallet-adapter-ant-design';
import { useAnchorWallet, useConnection } from '@solana/wallet-adapter-react';
import { web3 } from '@project-serum/anchor';
import 'antd/dist/antd.min.css';
import React, { useEffect, useState } from 'react';
import { Orders, Stat, Sell, Activity, Auctions } from '@liqnft/candy-shop';

import {
  CANDY_SHOP_PROGRAM_ID,
  CREATOR_ADDRESS,
  mainnetConnectionUrl,
  network,
  TREASURY_MINT
} from './constant/publicKey';
import Footer from './Footer';
import Header from './Header';
import { CandyShop } from '@liqnft/candy-shop-sdk';

type Tab = 'buy'|'sell'|'activity'|'auction'
const tabs = ['buy', 'sell', 'activity', 'auction']
export const CandyShopContent: React.FC = () => {
  const params = new URLSearchParams(window.location.search)
  const tab = params.get('tab') as Tab
  
  const [screen, setScreen] = useState<Tab>(tabs.includes(tab) ? tab : 'buy')
  const [auctionScreen, setAuctionScreen] = useState<'ongoing'|'upcoming'|'ended'>('ongoing')
  const { connection } = useConnection();
  const [candyShop, setCandyShop] = useState<CandyShop>();
  const [treasuryMint] = useState(new web3.PublicKey(TREASURY_MINT));
  const wallet = useAnchorWallet();

  useEffect(() => {
    if (!treasuryMint) return;
    setCandyShop(
      
      new CandyShop(
        {
          candyShopCreatorAddress: new web3.PublicKey(CREATOR_ADDRESS),
          treasuryMint: treasuryMint,
          candyShopProgramId: new web3.PublicKey(CANDY_SHOP_PROGRAM_ID),
          env: network,
          settings: {
            currencySymbol: 'SLC',
            currencyDecimals: 6,
            priceDecimals: 2,
            volumeDecimals: 1,
            mainnetConnectionUrl: mainnetConnectionUrl,
          },
        }
      )
    );
  }, [treasuryMint]);

  if (!candyShop) return null;

  return (
    <>
      <div
        style={{
          paddingBottom: 50,
          paddingLeft: 24,
          paddingRight: 24,
          textAlign: 'center'
        }}
      >
        <Header />
        <div className="main-banner">
          <div>
            <div className="main-banner-title">Solice Marketplace</div>
            <div className="main-banner-description">
              Welcome to the solice marketplace!
            </div>
            <div>
              <a href="https://solice.io/map" className="main-banner-button">
                Check out the map
              </a>
            </div>
          </div>
        </div>
        
        <div style={{ marginBottom: 50 }}>
          <Stat
            candyShop={candyShop}
            title={'Marketplace'}
            description={
              'Welcome to the Solice Metaverse Marketplace. Users can exchange official Solice Metaverse NFT collections here in SLC. All trades are peer-2-peer and are executed on and facilitated by the Solana network.'
            }
          />
          
        </div>
        <div className='screen-control'>
          <div className={`screen-control-button ${screen === 'buy' ? 'active' : ''}`} onClick={() => setScreen('buy')}>Buy</div>
          <div className={`screen-control-button ${screen === 'sell' ? 'active' : ''}`} onClick={() => setScreen('sell')}>Sell</div>
          <div className={`screen-control-button ${screen === 'auction' ? 'active' : ''}`} onClick={() => setScreen('auction')}>Auctions</div>
          <div className={`screen-control-button ${screen === 'activity' ? 'active' : ''}`} onClick={() => setScreen('activity')}>Activity</div>
        </div>
        {screen === 'buy' && (
          <div>
            <Orders
              wallet={wallet}
              candyShop={candyShop}
              walletConnectComponent={<WalletMultiButton />}
              filters={FILTERS}
            />
          </div>
        )}
        {screen === 'sell' && (
          <>
            <h1
              style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: 30 }}
            >
              Sell
            </h1>
            <Sell
              wallet={wallet}
              candyShop={candyShop}
              walletConnectComponent={<WalletMultiButton />}
            />
          </>
        )}
        {screen === 'activity' && (
          <>
            <h1
              style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: 30 }}
            >
              Activity
            </h1>
            <Activity
              candyShop={candyShop}
            />
          </>
        )}
        {screen === 'auction' && (
          <>
            <h1
              style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: 30 }}
            >
              Auctions
            </h1>
            <div className='screen-control screen-control-auction'>
              <div className={`screen-control-button ${auctionScreen === 'ongoing' ? 'active' : ''}`} onClick={() => setAuctionScreen('ongoing')}>Active</div>
              <div className={`screen-control-button ${auctionScreen === 'upcoming' ? 'active' : ''}`} onClick={() => setAuctionScreen('upcoming')}>Upcoming</div>
              <div className={`screen-control-button ${auctionScreen === 'ended' ? 'active' : ''}`} onClick={() => setAuctionScreen('ended')}>Ended</div>
            </div>
            {auctionScreen === 'ongoing' && (
              <Auctions
                walletConnectComponent={<WalletMultiButton />}
                candyShop={candyShop}
                wallet={wallet}
                statusFilters={[1]}
              />
            )}
            {auctionScreen === 'upcoming' && (
              <Auctions
                walletConnectComponent={<WalletMultiButton />}
                candyShop={candyShop}
                wallet={wallet}
                statusFilters={[0]}
              />
            )}
            {auctionScreen === 'ended' && (
              <Auctions
                walletConnectComponent={<WalletMultiButton />}
                candyShop={candyShop}
                wallet={wallet}
                statusFilters={[2,3,4]}
              />
            )}
          </>
        )}
      </div>
      <Footer />
    </>
  );
};

const FILTERS = [
  { name: 'Solice Land', identifier: -23115230 },
  { name: 'Genesis Avatar', identifier: -50222243 },
  { name: 'Genesis Staking', identifier: -1595400151 }
];
