{
    "version": "0.1.1",
    "name": "edition_drop",
    "instructions": [
        {
            "name": "shopCommitNft",
            "accounts": [
                {
                    "name": "commitNftCtx",
                    "accounts": [
                        {
                            "name": "masterEditionTokenAccountAuthority",
                            "isMut": true,
                            "isSigner": true
                        },
                        {
                            "name": "masterEditionTokenAccount",
                            "isMut": true,
                            "isSigner": false
                        },
                        {
                            "name": "vaultAccount",
                            "isMut": true,
                            "isSigner": false
                        },
                        {
                            "name": "vaultTokenAccount",
                            "isMut": true,
                            "isSigner": false
                        },
                        {
                            "name": "masterEditionMetadata",
                            "isMut": false,
                            "isSigner": false
                        },
                        {
                            "name": "masterEditionAccount",
                            "isMut": false,
                            "isSigner": false
                        },
                        {
                            "name": "masterEditionMint",
                            "isMut": false,
                            "isSigner": false
                        },
                        {
                            "name": "systemProgram",
                            "isMut": false,
                            "isSigner": false
                        },
                        {
                            "name": "tokenProgram",
                            "isMut": false,
                            "isSigner": false
                        },
                        {
                            "name": "associatedTokenProgram",
                            "isMut": false,
                            "isSigner": false
                        },
                        {
                            "name": "rent",
                            "isMut": false,
                            "isSigner": false
                        }
                    ]
                },
                {
                    "name": "candyShop",
                    "isMut": false,
                    "isSigner": false
                }
            ],
            "args": [
                {
                    "name": "price",
                    "type": "u64"
                },
                {
                    "name": "startingTime",
                    "type": "i64"
                },
                {
                    "name": "salesPeriod",
                    "type": "i64"
                },
                {
                    "name": "whitelistTime",
                    "type": {
                        "option": "i64"
                    }
                }
            ]
        },
        {
            "name": "enterpriseCommitNft",
            "accounts": [
                {
                    "name": "commitNftCtx",
                    "accounts": [
                        {
                            "name": "masterEditionTokenAccountAuthority",
                            "isMut": true,
                            "isSigner": true
                        },
                        {
                            "name": "masterEditionTokenAccount",
                            "isMut": true,
                            "isSigner": false
                        },
                        {
                            "name": "vaultAccount",
                            "isMut": true,
                            "isSigner": false
                        },
                        {
                            "name": "vaultTokenAccount",
                            "isMut": true,
                            "isSigner": false
                        },
                        {
                            "name": "masterEditionMetadata",
                            "isMut": false,
                            "isSigner": false
                        },
                        {
                            "name": "masterEditionAccount",
                            "isMut": false,
                            "isSigner": false
                        },
                        {
                            "name": "masterEditionMint",
                            "isMut": false,
                            "isSigner": false
                        },
                        {
                            "name": "systemProgram",
                            "isMut": false,
                            "isSigner": false
                        },
                        {
                            "name": "tokenProgram",
                            "isMut": false,
                            "isSigner": false
                        },
                        {
                            "name": "associatedTokenProgram",
                            "isMut": false,
                            "isSigner": false
                        },
                        {
                            "name": "rent",
                            "isMut": false,
                            "isSigner": false
                        }
                    ]
                },
                {
                    "name": "candyShop",
                    "isMut": false,
                    "isSigner": false
                }
            ],
            "args": [
                {
                    "name": "price",
                    "type": "u64"
                },
                {
                    "name": "startingTime",
                    "type": "i64"
                },
                {
                    "name": "salesPeriod",
                    "type": "i64"
                },
                {
                    "name": "whitelistTime",
                    "type": {
                        "option": "i64"
                    }
                }
            ]
        },
        {
            "name": "shopMintPrint",
            "accounts": [
                {
                    "name": "mintPrintCtx",
                    "accounts": [
                        {
                            "name": "newEditionBuyer",
                            "isMut": true,
                            "isSigner": true
                        },
                        {
                            "name": "vaultAccount",
                            "isMut": true,
                            "isSigner": false
                        },
                        {
                            "name": "vaultTokenAccount",
                            "isMut": true,
                            "isSigner": false
                        },
                        {
                            "name": "masterEditionMetadata",
                            "isMut": false,
                            "isSigner": false
                        },
                        {
                            "name": "masterEditionUpdateAuthority",
                            "isMut": false,
                            "isSigner": false
                        },
                        {
                            "name": "masterEditionAccount",
                            "isMut": true,
                            "isSigner": false
                        },
                        {
                            "name": "masterEditionMint",
                            "isMut": false,
                            "isSigner": false
                        },
                        {
                            "name": "masterEditionTokenAccount",
                            "isMut": true,
                            "isSigner": false
                        },
                        {
                            "name": "newEditionMetadata",
                            "isMut": true,
                            "isSigner": false
                        },
                        {
                            "name": "newEditionAccount",
                            "isMut": true,
                            "isSigner": false
                        },
                        {
                            "name": "newEditionMarker",
                            "isMut": true,
                            "isSigner": false
                        },
                        {
                            "name": "newEditionMint",
                            "isMut": true,
                            "isSigner": false
                        },
                        {
                            "name": "newEditionTokenAccount",
                            "isMut": true,
                            "isSigner": false
                        },
                        {
                            "name": "shopTreasuryAddress",
                            "isMut": true,
                            "isSigner": false
                        },
                        {
                            "name": "tokenMetadataProgram",
                            "isMut": false,
                            "isSigner": false
                        },
                        {
                            "name": "systemProgram",
                            "isMut": false,
                            "isSigner": false
                        },
                        {
                            "name": "tokenProgram",
                            "isMut": false,
                            "isSigner": false
                        },
                        {
                            "name": "rent",
                            "isMut": false,
                            "isSigner": false
                        }
                    ]
                },
                {
                    "name": "candyShop",
                    "isMut": false,
                    "isSigner": false
                }
            ],
            "args": [
                {
                    "name": "edition",
                    "type": "u64"
                }
            ]
        },
        {
            "name": "enterpriseMintPrint",
            "accounts": [
                {
                    "name": "mintPrintCtx",
                    "accounts": [
                        {
                            "name": "newEditionBuyer",
                            "isMut": true,
                            "isSigner": true
                        },
                        {
                            "name": "vaultAccount",
                            "isMut": true,
                            "isSigner": false
                        },
                        {
                            "name": "vaultTokenAccount",
                            "isMut": true,
                            "isSigner": false
                        },
                        {
                            "name": "masterEditionMetadata",
                            "isMut": false,
                            "isSigner": false
                        },
                        {
                            "name": "masterEditionUpdateAuthority",
                            "isMut": false,
                            "isSigner": false
                        },
                        {
                            "name": "masterEditionAccount",
                            "isMut": true,
                            "isSigner": false
                        },
                        {
                            "name": "masterEditionMint",
                            "isMut": false,
                            "isSigner": false
                        },
                        {
                            "name": "masterEditionTokenAccount",
                            "isMut": true,
                            "isSigner": false
                        },
                        {
                            "name": "newEditionMetadata",
                            "isMut": true,
                            "isSigner": false
                        },
                        {
                            "name": "newEditionAccount",
                            "isMut": true,
                            "isSigner": false
                        },
                        {
                            "name": "newEditionMarker",
                            "isMut": true,
                            "isSigner": false
                        },
                        {
                            "name": "newEditionMint",
                            "isMut": true,
                            "isSigner": false
                        },
                        {
                            "name": "newEditionTokenAccount",
                            "isMut": true,
                            "isSigner": false
                        },
                        {
                            "name": "shopTreasuryAddress",
                            "isMut": true,
                            "isSigner": false
                        },
                        {
                            "name": "tokenMetadataProgram",
                            "isMut": false,
                            "isSigner": false
                        },
                        {
                            "name": "systemProgram",
                            "isMut": false,
                            "isSigner": false
                        },
                        {
                            "name": "tokenProgram",
                            "isMut": false,
                            "isSigner": false
                        },
                        {
                            "name": "rent",
                            "isMut": false,
                            "isSigner": false
                        }
                    ]
                },
                {
                    "name": "candyShop",
                    "isMut": false,
                    "isSigner": false
                }
            ],
            "args": [
                {
                    "name": "edition",
                    "type": "u64"
                }
            ]
        },
        {
            "name": "redeemNft",
            "accounts": [
                {
                    "name": "nftOwner",
                    "isMut": true,
                    "isSigner": true
                },
                {
                    "name": "nftOwnerTokenAccount",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "vaultAccount",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "vaultTokenAccount",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "nftMint",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "systemProgram",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "tokenProgram",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "rent",
                    "isMut": false,
                    "isSigner": false
                }
            ],
            "args": []
        }
    ],
    "accounts": [
        {
            "name": "VaultAccount",
            "type": {
                "kind": "struct",
                "fields": [
                    {
                        "name": "candyShop",
                        "type": "publicKey"
                    },
                    {
                        "name": "nftOwner",
                        "type": "publicKey"
                    },
                    {
                        "name": "price",
                        "type": "u64"
                    },
                    {
                        "name": "maxSupply",
                        "type": "u64"
                    },
                    {
                        "name": "currentSupply",
                        "type": "u64"
                    },
                    {
                        "name": "startingTime",
                        "type": "i64"
                    },
                    {
                        "name": "salesPeriod",
                        "type": "i64"
                    },
                    {
                        "name": "whitelistTime",
                        "type": {
                            "option": "i64"
                        }
                    },
                    {
                        "name": "whitelistMint",
                        "type": {
                            "option": "publicKey"
                        }
                    }
                ]
            }
        }
    ],
    "types": [
        {
            "name": "MintPrintReturnType",
            "type": {
                "kind": "struct",
                "fields": [
                    {
                        "name": "isNative",
                        "type": "bool"
                    },
                    {
                        "name": "fees",
                        "type": "u64"
                    },
                    {
                        "name": "revenue",
                        "type": "u64"
                    }
                ]
            }
        },
        {
            "name": "VaultStatus",
            "type": {
                "kind": "enum",
                "variants": [
                    {
                        "name": "Created"
                    },
                    {
                        "name": "WhitelistStarted"
                    },
                    {
                        "name": "SaleStarted"
                    },
                    {
                        "name": "SaleCompleted"
                    }
                ]
            }
        }
    ],
    "errors": [
        {
            "code": 6000,
            "name": "MintSupplyNotFixed",
            "msg": "Mint supply not fixed"
        },
        {
            "code": 6001,
            "name": "MintDecimalsNotZero",
            "msg": "Mint decimal not zero"
        },
        {
            "code": 6002,
            "name": "SaleNotStarted",
            "msg": "Sales not started yet"
        },
        {
            "code": 6003,
            "name": "InvalidStatus",
            "msg": "Invalid status to update"
        },
        {
            "code": 6004,
            "name": "SaleEnded",
            "msg": "Sales ended"
        },
        {
            "code": 6005,
            "name": "InsufficientOtToken",
            "msg": "Insufficient ownership token for NFT redemption"
        },
        {
            "code": 6006,
            "name": "MetadataDoesntExist",
            "msg": "Metadata does not exist"
        },
        {
            "code": 6007,
            "name": "EditionDoesntExist",
            "msg": "Edition does not exist"
        },
        {
            "code": 6008,
            "name": "NotEnoughTokens",
            "msg": "Not enough tokens to mint a limited edition"
        },
        {
            "code": 6009,
            "name": "MetadataNotFound",
            "msg": "Metadata not found"
        },
        {
            "code": 6010,
            "name": "MasterEditionNotFound",
            "msg": "Master edition not found"
        },
        {
            "code": 6011,
            "name": "Uninitialized",
            "msg": "Uninitialized"
        },
        {
            "code": 6012,
            "name": "InvalidMint",
            "msg": "Invalid mint"
        },
        {
            "code": 6013,
            "name": "MasterEditionSupplyNotSet",
            "msg": "Master Edition should have supply set"
        },
        {
            "code": 6014,
            "name": "MasterEditionSupplyCannotBeZero",
            "msg": "Master Edition max supply cannot be zero"
        },
        {
            "code": 6015,
            "name": "ExceededMaxAllowedSupply",
            "msg": "Exceeded max allowed supply"
        },
        {
            "code": 6016,
            "name": "AlreadyInitialized",
            "msg": "Already initialized"
        },
        {
            "code": 6017,
            "name": "NumericalOverflow",
            "msg": "Invalid numerical inputs"
        },
        {
            "code": 6018,
            "name": "RedeemConditionNotMet",
            "msg": "Redeem condition not met"
        },
        {
            "code": 6019,
            "name": "WhitelistTimeNotSet",
            "msg": "whitelist time not set"
        },
        {
            "code": 6020,
            "name": "WhitelistAccountsWronglySupplied",
            "msg": "missing whitelist accounts"
        },
        {
            "code": 6021,
            "name": "InvalidWhitelistMint",
            "msg": "invalid whitelist mint"
        },
        {
            "code": 6022,
            "name": "InvalidWhitelistTokenAccount",
            "msg": "invalid whitelist token account"
        },
        {
            "code": 6023,
            "name": "InsufficientWhitelistToken",
            "msg": "insufficient whitelist token"
        },
        {
            "code": 6024,
            "name": "InvalidWhitelistMintDecimal",
            "msg": "invalid whitelist mint decimals"
        },
        {
            "code": 6025,
            "name": "InvalidWhitelistTime",
            "msg": "invalid whitelist time"
        },
        {
            "code": 6026,
            "name": "InvalidEditionIndex",
            "msg": "invalid edition number"
        },
        {
            "code": 6027,
            "name": "EditionTaken",
            "msg": "edition already taken"
        },
        {
            "code": 6028,
            "name": "InvalidShopKey",
            "msg": "key of provided candy shop does not match expected value"
        },
        {
            "code": 6029,
            "name": "MintNonNative",
            "msg": "Candy Shop treasury mint must be native"
        },
        {
            "code": 6030,
            "name": "InvalidNftOwner",
            "msg": "Invalid nft owner provided in remaining accounts"
        },
        {
            "code": 6031,
            "name": "InvalidStartTime",
            "msg": "invalid start time"
        },
        {
            "code": 6032,
            "name": "InvalidUpdateAuthority",
            "msg": "invalid update authority"
        }
    ],
    "metadata": {
        "address": "eddn8tqKsfj3oezin66nmoK5PYrq28dEWMXZYxoQUus"
    }
}
